











































import {
  api
} from "@/api";
import {
  ParamModule
} from "@/store/modules/param";
import {
  WechatModule
} from "@/store/modules/wechat";
import {
  Component,
  Vue
} from "vue-property-decorator";

@Component({
  name: 'set-tag',
})
export default class SetTag extends Vue {
  private tagList: any = []
  private allTags: any = []
  private showCreateTag: boolean = false
  private tagName = ''
  private friendId: number = +this.$route.query.friendId
  private loading: any = null

  private get friendTag(): any {
    console.log(ParamModule.friendTag)
    return ParamModule.friendTag ? JSON.parse(ParamModule.friendTag) : []
  }

  private get wxId(): number {
    return WechatModule.wxId
  }

  private mounted() {
    this.tagList = this.friendTag.map((item: any) => {
      return item.name
    })
    this.getRobotTag()
  }

  private toCreate() {
    this.showCreateTag = true
    this.$nextTick(() => {
      const refs: any = this.$refs.newInput
      refs.focus()
    })
  }

  private closeDialog() {
    this.showCreateTag = false
    this.tagName = ''
  }

  /**
   * @func 获取设备标签
   */
  private async getRobotTag() {
    try {
      const res: any = await api.getRobotTagList({})
      this.allTags = res.tags
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @func 点击标签
   * @param tag 标签内容
   */
  private clickTag(tag: string) {
    let index = this.tagList.indexOf(tag)
    if (index !== -1) {
      this.tagList.splice(index, 1)
    } else {
      this.tagList.push(tag)
    }
  }

  /**
   * @func 设置新标签
   */
  private async createTag() {
    this.loading = this.$weui.loading('创建中...')
    try {
      await api.createRobotTags({
        name: this.tagName,
      })
      this.tagList.push(this.tagName)
      this.allTags.push({
        id: 0,
        name: this.tagName
      })
      // this.closeDialog()
      this.setFriendTag()
    } catch (err) {
      this.loading.hide()
      console.log(err)
    }
  }

  /**
   * @func 提交
   */
  private submit() {
    this.loading = this.$weui.loading('更新中...')
    this.setFriendTag()
  }

  private async setFriendTag() {
    try {
      await api.setFriendTags({
        wxid: this.wxId,
        friend_id: this.friendId,
        tags: this.tagList
      })
      this.loading.hide()
      this.$router.go(-1)
    } catch (err) {
      this.loading.hide()
      console.log(err)
    }
  }

  private beforeDestroy() {
    ParamModule.changeFriendTag('')
  }

}
